import React, { memo, useCallback, useRef } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import Information from '@atlaskit/icon/core/migration/information--info';
import { token } from '@atlaskit/tokens';
import { useFeedbackCollector } from '@atlassian/jira-business-feedback-collector';
import { ThemedButton } from '@atlassian/jira-business-theme-components/src/ui/themed-button/ThemedButton.tsx';
import { Tokens } from '@atlassian/jira-custom-theme-constants/src/constants.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

const FEEDBACK_GROUP_ID = 'jwm-feedback-summary';

type Props = {
	isLoading: boolean;
};

const FeedbackBanner = ({ isLoading }: Props) => {
	const { formatMessage } = useIntl();
	const buttonRef = useRef<HTMLButtonElement>(null);
	const openFeedbackModal = useFeedbackCollector();

	const onSubmitFeedbackClick = useCallback(() => {
		openFeedbackModal(FEEDBACK_GROUP_ID, noop, buttonRef);
	}, [openFeedbackModal]);

	return (
		<FeedbackBannerWrapper
			isLoading={isLoading}
			data-testid="business-summary.ui.summary-view.feedback-banner"
		>
			<Information
				LEGACY_size="medium"
				LEGACY_primaryColor={Tokens.COLOR_TEXT_SUBTLE}
				LEGACY_secondaryColor={Tokens.COLOR_ELEVATION_SURFACE}
				label=""
				color="currentColor"
			/>
			<ParagraphWrapper>
				{formatMessage(messages.inviteFeedbackMessage)}
				<ThemedButton
					appearance="link"
					spacing="none"
					onClick={onSubmitFeedbackClick}
					ref={buttonRef}
				>
					{formatMessage(messages.submitFeedbackCallToAction)}
				</ThemedButton>
			</ParagraphWrapper>
		</FeedbackBannerWrapper>
	);
};

export default memo<Props>(FeedbackBanner);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FeedbackBannerWrapper = styled.div<{ isLoading: boolean }>({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	margin: `${token('space.250', '20px')} auto 0`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	color: Tokens.COLOR_TEXT,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	visibility: (props) => (props.isLoading ? 'hidden' : 'visible'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	background: Tokens.ELEVATION_SURFACE_CONTRAST,
	padding: `${token('space.050', '4px')} ${token('space.100', '8px')}`,
	borderRadius: token('border.radius.100', '4px'),
	width: 'fit-content',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ParagraphWrapper = styled.p({
	margin: `0 0 0 ${token('space.100', '8px')}`,
	lineHeight: '24px',
});
