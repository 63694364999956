import React from 'react';
import { styled } from '@compiled/react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { N20A, N40, N600, N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { gridSize, borderRadius } from '@atlassian/jira-common-styles/src/main.tsx';
import { Link } from '@atlassian/react-resource-router';
import Skeleton from './skeleton';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CenteredSkeleton = styled(Skeleton)({
	flex: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Description = styled.p({
	margin: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ChartWrapper = styled.div({
	flex: 1,
	display: 'flex',
	alignItems: 'center',
	minHeight: 0,
	marginTop: token('space.200', '16px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Chart = styled(ReactEChartsCore)({
	flex: '1 1 50%',
});

type EmptyChartProps = {
	className?: string;
	testId: string;
};

const EmptyChartSvg = ({ className, testId }: EmptyChartProps) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		version="1.1"
		viewBox="0 0 100 100"
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
		className={className}
		data-testid={testId}
	>
		<circle
			cx="50"
			cy="50"
			r="36"
			stroke={token('color.background.accent.gray.subtler', N40)}
			strokeWidth="22.5"
			fill="none"
		/>
	</svg>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const EmptyChart = styled(EmptyChartSvg)({
	flex: '1 1 50%',
	width: '240px',
	height: '240px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Legend = styled.div({
	flex: '1 1 50%',
	boxSizing: 'border-box',
	minWidth: 0,
	maxHeight: '100%',
	marginRight: token('space.negative.100', '-8px'),
	paddingRight: token('space.100', '8px'),
	overflowY: 'auto',
	paddingLeft: token('space.300', '24px'),
	fontWeight: 500,
	color: token('color.text.subtle', N600),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LegendList = styled.ol({
	listStyle: 'none',
	padding: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LegendItemColor = styled.div<{ color: string }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ color }) => color,
	flex: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 2}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 2}px`,
	marginRight: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius}px`,
	// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
	boxShadow: 'inset 0px 0px 0px 1px #0000001a',
	opacity: 'var(--icon-opacity)',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LegendItem = styled.li({
	display: 'flex',
	alignItems: 'center',
	marginBottom: token('space.050', '4px'),
	padding: `${token('space.025', '2px')} ${token('space.100', '8px')} ${token(
		'space.025',
		'2px',
	)} ${token('space.050', '4px')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius}px`,
	'--icon-opacity': '1',
	'&:hover': {
		color: token('color.text', N800),
		backgroundColor: token('color.background.neutral.subtle.hovered', N20A),
		'--icon-opacity': '0.9',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LegendItemName = styled.span({
	flex: 1,
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LegendItemCount = styled(Link)({
	flex: 'none',
	marginLeft: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LegendTotal = styled.div({
	marginTop: token('space.100', '8px'),
	padding: `0 ${token('space.100', '8px')} 0 ${token('space.050', '4px')}`,
	fontWeight: 700,
	display: 'flex',
	alignItems: 'center',
});
