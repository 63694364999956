import React, { forwardRef, memo, useCallback, type ReactNode, type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button/standard-button';
import Heading from '@atlaskit/heading';
import { N0, N20A, N50A, N40A, N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { CARD_BORDER_RADIUS, CARD_MIN_WIDTH, CARD_MARGIN } from '../../constants';
import ErrorIllustration from './error-illustration';
import messages from './messages';

type Props = {
	isLoading?: boolean;
	children: ReactNode;
	testId: string;
	title: string;
	onPointerEnter?: (arg1: SyntheticEvent) => void;
	onPointerLeave?: (arg1: SyntheticEvent) => void;
};

const Card = forwardRef<HTMLDivElement, Props>(
	({ children, testId, title, isLoading = false, onPointerEnter, onPointerLeave }, ref) => {
		let header;
		if (isLoading) {
			header = <TitleSkeleton />;
		} else {
			header = <Title>{title}</Title>;
		}

		return (
			<Container
				ref={ref}
				data-testid={testId}
				onPointerEnter={onPointerEnter}
				onPointerLeave={onPointerLeave}
			>
				{header}
				{children}
			</Container>
		);
	},
);

export default Card;

export const ErrorCard = memo<{}>(() => {
	const { formatMessage } = useIntl();

	const refresh = useCallback(() => window.location.reload(), []);

	return (
		<ErrorContainer>
			<ErrorIllustration />

			<ErrorHeader level="h500" as="h4">
				{formatMessage(messages.title)}
			</ErrorHeader>

			<p>{formatMessage(messages.description)}</p>

			<Button appearance="link" onClick={refresh}>
				{formatMessage(messages.refresh)}
			</Button>
		</ErrorContainer>
	);
});

const TITLE_MARGIN_BOTTOM = token('space.200', '16px');

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	flexDirection: 'column',
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	margin: `${CARD_MARGIN}px`,
	background: token('elevation.surface.raised', N0),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${CARD_BORDER_RADIUS}px`,
	padding: `${token('space.300', '24px')} ${token('space.400', '32px')}`,
	height: '400px',
	flex: 1,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minWidth: `${CARD_MIN_WIDTH}px`,
	color: token('color.text', N800),
	boxShadow: token('elevation.shadow.raised', `0 1px 1px ${N50A}, 0 0 1px 1px ${N40A}`),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Title = styled.h4({
	color: token('color.text', N800),
	flex: 'none',
	marginBottom: TITLE_MARGIN_BOTTOM,
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitleSkeleton = styled.div({
	backgroundColor: token('color.skeleton', N20A),
	borderRadius: '4px',
	marginBottom: TITLE_MARGIN_BOTTOM,
	flex: 'none',
	width: '160px',
	height: '20px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorContainer = styled(Container)({
	justifyContent: 'center',
	alignItems: 'center',
	textAlign: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorHeader = styled(Heading)({
	marginTop: token('space.300', '24px'),
});
