import React, { memo } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import UFOSegment from '@atlassian/jira-ufo-segment';
import { CARD_MIN_WIDTH, CARD_MARGIN } from '../../../common/constants';
import { useOverviewItems } from '../../../controllers/summary-context';
import OverviewCard from './overview-card';
import { DONE, UPDATED, CREATED, DUE } from './overview-card/constants';

const OverviewItems = () => {
	const { data, loading } = useOverviewItems();

	return (
		<UFOSegment name="business-summary-overview-items">
			<ListContainer>
				<CardsList>
					<OverviewCard
						count={data?.doneThisWeek}
						type={DONE}
						isLoading={loading}
						testId="business-summary.ui.summary-view.overview-items.done-this-week"
					/>
					<OverviewCard
						count={data?.updatedThisWeek}
						type={UPDATED}
						isLoading={loading}
						testId="business-summary.ui.summary-view.overview-items.updated-this-week"
					/>
					<OverviewCard
						count={data?.createdThisWeek}
						type={CREATED}
						isLoading={loading}
						testId="business-summary.ui.summary-view.overview-items.created-this-week"
					/>
					<OverviewCard
						count={data?.dueThisWeek}
						type={DUE}
						isLoading={loading}
						testId="business-summary.ui.summary-view.overview-items.due-this-week"
					/>
				</CardsList>
			</ListContainer>
		</UFOSegment>
	);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default memo<Record<any, any>>(OverviewItems);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ListContainer = styled.div({
	display: 'flex',
	flexGrow: 1,
	padding: `${token('space.100', '8px')} 0px`,
	width: '100%',
	gridAutoFlow: 'row dense',
	containerType: 'inline-size',
	containerName: 'cardContainer',

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-container-queries, @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	[`@container cardContainer (max-width: ${(CARD_MIN_WIDTH + CARD_MARGIN) * 2}px)`]: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		ul: {
			gridTemplateColumns: '1fr 1fr',
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-container-queries, @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	[`@container cardContainer (max-width: ${CARD_MIN_WIDTH}px)`]: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		ul: {
			gridTemplateColumns: '1fr',
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardsList = styled.ul({
	padding: 0,
	listStyle: 'none',
	display: 'grid',
	justifyContent: 'center',
	gridTemplateColumns: 'repeat(4, 1fr)',
	columnGap: token('space.200', '16px'),
	rowGap: token('space.200', '16px'),
	minWidth: 0,
	width: '100%',
});
