/* eslint-disable no-bitwise */
import React, { createElement, type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { N30A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import type { ActivityFeedItem } from '@atlassian/jira-business-summary-services/src/services/activity-feed/types.tsx';
import { codeFontFamily, gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import AccessibleLink from '../../../../common/ui/accessible-link';

export const transformTitle = (
	item: ActivityFeedItem,
	onClick: (target: 'user' | 'issue') => void,
	linkTarget: '_self' | '_blank',
): ReactNode => {
	const parser = new DOMParser();
	const { body } = parser.parseFromString(item.title, 'text/html');

	if (body == null) {
		return null;
	}

	const walker = document.createTreeWalker(body, NodeFilter.SHOW_TEXT | NodeFilter.SHOW_ELEMENT);
	let node = walker.firstChild();
	const result = [];
	let key = 0;
	do {
		if (node == null) {
			// eslint-disable-next-line no-continue
			continue;
		}
		if (node.nodeType === Node.ELEMENT_NODE && node.nodeName === 'A') {
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			const href = (node as HTMLElement).getAttribute('href') || '';

			if (href.startsWith('/browse/')) {
				result.push(
					<AccessibleLink
						key={key++}
						target={linkTarget}
						href={href}
						onClick={() => onClick('issue')}
					>
						<TypeIcon src={item.iconUrl} alt="" />
						{node.textContent}
					</AccessibleLink>,
				);
			} else {
				result.push(
					<AccessibleLink
						key={key++}
						target={linkTarget}
						href={href}
						onClick={() => onClick('user')}
					>
						{node.textContent}
					</AccessibleLink>,
				);
			}
		} else {
			result.push(node.textContent);
		}
		// eslint-disable-next-line no-cond-assign
	} while ((node = walker.nextSibling()));

	return result;
};

type NodeMapper = (walker: TreeWalker, keyGen: Generator<number>) => ReactNode;
type NodeMapperMap = {
	[key: string]: NodeMapper;
};

function* infinite() {
	let index = 0;

	while (true) {
		yield index++;
	}
}

const mapSiblingNodes = (walker: TreeWalker, keyGen: Generator<number>): ReactNode[] | null => {
	const mappedNodes = [];
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	let currentNode = walker.currentNode as Node | null;

	while (currentNode) {
		const { nodeType } = currentNode;
		if (nodeType === Node.TEXT_NODE) {
			mappedNodes.push(currentNode.textContent);
		} else if (nodeType === Node.ELEMENT_NODE) {
			const nodeName = currentNode.nodeName.toLowerCase();

			if (nodeName in NODE_MAPPER_MAP) {
				const result = NODE_MAPPER_MAP[nodeName](walker, keyGen);

				if (result != null) {
					mappedNodes.push(result);
				}
			}
		}

		currentNode = walker.nextSibling();
	}

	if (mappedNodes.length === 0) {
		return null;
	}

	return mappedNodes;
};

const mapChildrenNodes = (walker: TreeWalker, keyGen: Generator<number>): ReactNode[] | null => {
	const firstChild = walker.firstChild();

	if (firstChild) {
		const result = mapSiblingNodes(walker, keyGen);
		walker.parentNode();

		return result;
	}

	return null;
};

const identity: NodeMapper = (walker, keyGen) => {
	const children = mapChildrenNodes(walker, keyGen);

	return createElement(
		walker.currentNode.nodeName.toLowerCase(),
		{ key: keyGen.next().value },
		children,
	);
};

const isNodeEmpty = (children: ReactNode[]) =>
	children.length === 1 && typeof children[0] === 'string' && children[0].trim().length === 0;

const NODE_MAPPER_MAP: NodeMapperMap = {
	a: (walker, keyGen) => {
		const children = mapChildrenNodes(walker, keyGen);

		if (children == null || children.length === 0) {
			return null;
		}

		return (
			<StyledAnchor
				key={keyGen.next().value}
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				href={(walker.currentNode as HTMLElement).getAttribute('href') as string}
				target="_blank"
			>
				{children}
			</StyledAnchor>
		);
	},
	b: identity,
	blockquote: (walker, keyGen) => {
		const children = mapChildrenNodes(walker, keyGen);

		if (children == null || children.length === 0 || isNodeEmpty(children)) {
			return null;
		}

		return <blockquote key={keyGen.next().value}>{children}</blockquote>;
	},
	br: identity,
	div: (walker, keyGen) => {
		const children = mapChildrenNodes(walker, keyGen);

		if (children == null || children.length === 0) {
			return null;
		}

		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		if ((walker.currentNode as HTMLElement).classList.contains('user-content')) {
			// if there is only a text node as child and it's blank space, skip it (stupid stream...)
			if (isNodeEmpty(children)) {
				return null;
			}

			return <blockquote key={keyGen.next().value}>{children}</blockquote>;
		}

		return children;
	},
	em: identity,
	font: mapChildrenNodes,
	h1: identity,
	h2: identity,
	h3: identity,
	h4: identity,
	h5: identity,
	h6: identity,
	hr: identity,
	img: (walker, keyGen) => (
		<img
			key={keyGen.next().value}
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			src={(walker.currentNode as HTMLElement).getAttribute('src') as string}
			alt=""
		/>
	),
	li: identity,
	ol: identity,
	p: identity,
	pre: identity,
	span: mapChildrenNodes,
	sub: identity,
	sup: identity,
	table: identity,
	tbody: identity,
	td: identity,
	th: identity,
	thead: identity,
	tr: identity,
	ul: identity,
};

export const transformContent = (item: ActivityFeedItem): ReactNode => {
	if (item.content == null) {
		return null;
	}

	const parser = new DOMParser();
	const { body } = parser.parseFromString(item.content, 'text/html');
	if (body == null) {
		return null;
	}

	const walker = document.createTreeWalker(body, NodeFilter.SHOW_TEXT | NodeFilter.SHOW_ELEMENT);
	const keyGen = infinite();

	const children = mapChildrenNodes(walker, keyGen);

	if (children == null || children.length === 0) {
		return null;
	}

	return (
		<Content data-testid="business-summary.ui.summary-view.activity-feed.activity-item.content">
			{children}
		</Content>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Content = styled.div({
	margin: `${token('space.050', '4px')} 0 0 44px`,
	wordBreak: 'break-word',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	hr: {
		border: 'none',
		height: '2px',
		margin: `${token('space.200', '16px')} 0`,
		backgroundColor: token('color.border', '#091e4224'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& + p': {
			marginTop: 0,
		},
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'ul, ol': {
		margin: 0,
		padding: `0 0 0 ${token('space.200', '16px')}`,
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	ul: {
		listStyle: 'disc',
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	blockquote: {
		padding: `${token('space.100', '8px')} ${token('space.200', '16px')}`,
		borderLeft: `2px solid ${token('color.border', '#091e4224')}`,
		'&::before, &::after': {
			display: 'none',
		},
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> blockquote': {
		margin: 0,
		padding: `${token('space.100', '8px')} ${token('space.150', '12px')}`,
		border: `1px solid ${token('color.border', '#091e4224')}`,
		borderRadius: token('border.radius.100', '4px'),
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	img: {
		maxWidth: '100%',
		maxHeight: '200px',
		width: 'auto',
		height: 'auto',
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	pre: {
		padding: `${token('space.100', '8px')} ${token('space.150', '12px')}`,
		background: token('elevation.surface.sunken', '#f7f8f9'),
		whiteSpace: 'pre-wrap',
		font: token('font.body.UNSAFE_small'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		fontFamily: codeFontFamily,
	},

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	table: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
		display: 'block !important',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& > tbody': {
			border: 'none',
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'th, td': {
				border: `1px solid ${token('color.border', '#091e4224')}`,
				padding: `${token('space.050', '4px')} ${token('space.100', '8px')}`,
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			th: {
				backgroundColor: token('color.background.neutral', N30A),
			},
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TypeIcon = styled.img({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${gridSize * 2}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${gridSize * 2}px`,
	marginRight: token('space.025', '2px'),
	verticalAlign: 'middle',
	display: 'inline-flex',
	paddingBottom: token('space.025', '2px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledAnchor = styled.a({
	textDecoration: 'underline',
});
