import React, { Fragment, memo, useMemo } from 'react';
import { styled } from '@compiled/react';
import { formatInTimeZone } from 'date-fns-tz';
import isToday from 'date-fns/isToday';
import isYesterday from 'date-fns/isYesterday';
import Spinner from '@atlaskit/spinner';
import { N0, N30, N300 } from '@atlaskit/theme/colors';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import {
	ExperienceSuccess,
	ExperienceFailed,
} from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import { useScrollToEnd } from '@atlassian/jira-business-scroll-to-end';
import type { ActivityFeedItem } from '@atlassian/jira-business-summary-services/src/services/activity-feed/types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import UFOSegment from '@atlassian/jira-ufo-segment';
import UFOLoadHold from '@atlassian/react-ufo/load-hold';
import { ACTIVITY_FEED_EXPERIENCE } from '../../../common/constants';
import type { SectionProps } from '../../../common/types';
import Card, { ErrorCard } from '../../../common/ui/card';
import CreateItemLink from '../../../common/ui/create-item-link';
import { useSectionAnalytics } from '../../../common/utils';
import useActivityFeed from '../../../controllers/activity-feed';
import { useSummaryFeatures } from '../../../controllers/features-context';
import ActivityItem from './activity-item';
import EmptyIllustration from './empty-illustration';
import messages from './messages';
import Skeleton from './skeleton';

type GroupedActivities = {
	key: string;
	label: string;
	items: ActivityFeedItem[];
}[];

const ActivityFeed = ({ position }: SectionProps) => {
	const { formatMessage, formatDate } = useIntl();
	const { hasCreateItem, linksTarget, urlBuilder } = useSummaryFeatures();
	const { data, loading, loadingNext, hasNextPage, fetchNext, error } = useActivityFeed();
	const { startHoverTimer, stopHoverTimer, fireSectionAnalytics } = useSectionAnalytics(
		position,
		'activityFeed',
	);

	const { onScroll } = useScrollToEnd({
		onScrollToEnd: fetchNext,
		isDisabled: loadingNext || !hasNextPage,
		options: {
			onEndReachedThreshold: 150,
		},
	});

	const itemsByDate: GroupedActivities | null = useMemo(
		() =>
			data != null
				? // eslint-disable-next-line @typescript-eslint/no-explicit-any
					data.reduce<Array<any>>((groups, item) => {
						const publishedDate = new Date(item.published);
						const key = formatInTimeZone(publishedDate, 'UTC', 'yyyy-MM-dd');
						const lastGroup = groups[groups.length - 1];

						if (lastGroup?.key === key) {
							lastGroup.items.push(item);
						} else {
							let label;
							if (isToday(publishedDate)) {
								label = formatMessage(messages.today);
							} else if (isYesterday(publishedDate)) {
								label = formatMessage(messages.yesterday);
							} else {
								label = formatDate(key, {
									weekday: 'long',
									year: 'numeric',
									month: 'long',
									day: 'numeric',
								});
							}

							groups.push({ key, label, items: [item] });
						}

						return groups;
					}, [])
				: null,
		[data, formatDate, formatMessage],
	);

	if (error != null) {
		return (
			<>
				<ErrorCard />
				<ExperienceFailed experience={ACTIVITY_FEED_EXPERIENCE} error={error} />
			</>
		);
	}

	let content = null;

	if (loading) {
		content = (
			<UFOLoadHold name="activity-feed">
				<CenteredSkeleton testId="business-summary.ui.summary-view.activity-feed.skeleton" />
			</UFOLoadHold>
		);
	} else if (itemsByDate != null && itemsByDate.length === 0) {
		content = (
			<>
				<Description>
					{formatMessage(messages.descriptionWhenEmpty)}
					{hasCreateItem && (
						<>
							{' '}
							<CreateItemLink
								href={`${urlBuilder('activityFeed')}?inlineCreate=true`}
								onClick={() =>
									fireSectionAnalytics('view', {
										inlineCreate: true,
									})
								}
								target={linksTarget}
							/>
						</>
					)}
				</Description>

				<EmptyStateIllustration />

				<ExperienceSuccess experience={ACTIVITY_FEED_EXPERIENCE} />
			</>
		);
	} else if (itemsByDate != null) {
		content = (
			<>
				<Description>{formatMessage(messages.description)}</Description>

				<List onScroll={onScroll}>
					{itemsByDate.map(({ key, label, items }) => (
						<Fragment key={key}>
							<GroupTitle>{label}</GroupTitle>
							<GroupContent>
								<Items>
									{items.map((item) => (
										<ActivityItem
											key={item.id}
											item={item}
											onClick={(target) => fireSectionAnalytics(target)}
										/>
									))}
								</Items>
							</GroupContent>
						</Fragment>
					))}

					{loadingNext && (
						<SpinnerWrapper>
							<Spinner />
						</SpinnerWrapper>
					)}
				</List>

				<ExperienceSuccess experience={ACTIVITY_FEED_EXPERIENCE} />
			</>
		);
	}

	return (
		<UFOSegment name="business-summary-activity-feed">
			<Card
				title={formatMessage(messages.title)}
				isLoading={loading}
				testId="business-summary.ui.summary-view.activity-feed"
				onPointerEnter={startHoverTimer}
				onPointerLeave={stopHoverTimer}
			>
				{content}
			</Card>
		</UFOSegment>
	);
};

export default memo<SectionProps>(ActivityFeed);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CenteredSkeleton = styled(Skeleton)({
	flex: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Description = styled.p({
	margin: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const List = styled.dl({
	overflowY: 'auto',
	margin: `${token('space.100', '8px')} ${token('space.negative.300', '-24px')} 0`,
	padding: `0 ${token('space.300', '24px')}`,
	background: `linear-gradient(${token('elevation.surface.raised', N0)}, ${token(
		'elevation.surface.raised',
		N0,
	)}) 0 0 / 100% 2px no-repeat local border-box, linear-gradient(${token(
		'elevation.surface.raised',
		N0,
	)}, ${token(
		'elevation.surface.raised',
		N0,
	)}) 0 100% / 100% 2px no-repeat local border-box, linear-gradient(${token(
		'color.border',
		N30,
	)}, ${token(
		'color.border',
		N30,
	)}) 0 0 / 100% 2px no-repeat scroll content-box, linear-gradient(${token(
		'color.border',
		N30,
	)}, ${token('color.border', N30)}) 0 100% / 100% 2px no-repeat scroll content-box`,
});

// necessary to override base styles
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GroupTitle = styled.dt({
	color: token('color.text.subtlest', N300),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body.small', fontFallback.body.small),
	fontWeight: token('font.weight.medium', '500'),
	textTransform: 'uppercase',
	margin: `${token('space.050', '4px')} 0`,
	padding: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:first-child': {
		marginTop: token('space.050', '4px'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const GroupContent = styled.dd({
	margin: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Items = styled.ol({
	listStyle: 'none',
	padding: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinnerWrapper = styled.div({
	display: 'grid',
	justifyContent: 'center',
	margin: token('space.150', '12px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyStateIllustration = styled(EmptyIllustration)({
	width: '160px',
	margin: 'auto',
});
