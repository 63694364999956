import { useEffect, useMemo } from 'react';
import { usePagination } from '@atlassian/jira-business-pagination';
import type { IssuesForAssignee } from '@atlassian/jira-business-summary-services/src/services/types.tsx';
import useFetchIssuesByAssignee from '../../services/issues-by-assignee';
import { useIssuesByAssignee } from '../summary-context';
import type { IssuesByAssigneeType } from './types';

const usePagedIssuesByAssignee = (): IssuesByAssigneeType => {
	const { data: initialData, loading: initialLoading, error: initialError } = useIssuesByAssignee();

	const fetchIssuesByAssignee = useFetchIssuesByAssignee();

	const {
		data: assignees,
		error: nextPageError,
		hasNextPage,
		loadingNext,
		fetchNext,
		reset,
	} = usePagination<IssuesForAssignee, Error>(fetchIssuesByAssignee);

	useEffect(() => {
		if (initialData != null) {
			reset({ data: initialData.data, pageInfo: initialData.pageInfo });
		}
	}, [initialData, reset]);

	const data = useMemo(
		() =>
			initialData && assignees
				? { assignees, totalUnresolvedIssues: initialData.totalUnresolvedIssues }
				: null,
		[assignees, initialData],
	);

	return {
		data,
		loading: initialLoading,
		loadingNext,
		error: initialError || nextPageError,
		hasNextPage,
		fetchNext,
	};
};

export default usePagedIssuesByAssignee;
