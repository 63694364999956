import React, { memo, type SyntheticEvent } from 'react';
import AddIcon from '@atlaskit/icon/utility/add';
import { Box, xcss, Pressable } from '@atlaskit/primitives';
import Text from '@atlaskit/primitives/text';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages';

type Props = {
	onClick: (event: SyntheticEvent) => void;
};

const AddIssueTypesButton = ({ onClick }: Props) => {
	const { formatMessage } = useIntl();

	return (
		<Pressable
			onClick={onClick}
			backgroundColor="color.background.neutral.subtle"
			xcss={buttonStyles}
		>
			<Box xcss={addIconWrapperStyles}>
				<AddIcon label="" color={token('color.icon')} />
			</Box>
			<Text weight="medium">{formatMessage(messages.addIssueTypes)}</Text>
		</Pressable>
	);
};

export default memo<Props>(AddIssueTypesButton);

const addIconWrapperStyles = xcss({
	height: '24px',
	width: '24px',
	marginInlineEnd: 'space.075',
	paddingBlock: 'space.100',
	paddingInline: 'space.050',
	borderRadius: 'border.radius',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: 'color.background.neutral',
});

const buttonStyles = xcss({
	padding: 'space.025',
	marginBlock: 'space.100',
	color: 'color.text.subtlest',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'left',
	borderRadius: 'border.radius',
	borderWidth: 'border.width',
	':hover': {
		textDecoration: 'underline',
	},
});
