import React, { memo } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { OPEN_ISSUES_JQL } from '@atlassian/jira-business-summary-services/src/services/constants.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { FormattedI18nMessage } from '@atlassian/jira-formatted-i18n-message/src/ui/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import UFOSegment from '@atlassian/jira-ufo-segment';
import UFOLoadHold from '@atlassian/react-ufo/load-hold';
import type { SectionProps } from '../../../common/types';
import AccessibleLink from '../../../common/ui/accessible-link';
import Card from '../../../common/ui/card';
import CreateItemLink from '../../../common/ui/create-item-link';
import PercentageBarTable from '../../../common/ui/percentage-bar-table';
import PercentageBarRow from '../../../common/ui/percentage-bar-table/percentage-bar-row';
import { useSectionAnalytics } from '../../../common/utils';
import { useSummaryFeatures } from '../../../controllers/features-context';
import useIssuesByType from '../../../controllers/paged-issues-by-type';
import AddIssueTypesButton from './add-issue-types-button';
import ManageTypesButton from './manage-types-button';
import { ICON_SIZE } from './manage-types-button/styled';
import messages from './messages';
import { CenteredSkeleton } from './skeleton/styled';

const TypesOfWork = ({ position }: SectionProps) => {
	const { formatMessage } = useIntl();
	const { data, loading, fetchNext, loadingNext } = useIssuesByType();
	const { hasCreateItem, hasManageTypesButton, linksTarget, urlBuilder } = useSummaryFeatures();

	const { startHoverTimer, stopHoverTimer, fireSectionAnalytics } = useSectionAnalytics(
		position,
		'typesOfWork',
	);

	let content;

	if (loading) {
		content = (
			<UFOLoadHold name="types-of-work">
				<CenteredSkeleton testId="business-summary.ui.summary-view.types-of-work.skeleton" />
			</UFOLoadHold>
		);
	} else {
		const headers = {
			identifier: formatMessage(messages.issueTypeColumnHeader),
			percentageBar: formatMessage(messages.issueDistributionColumnHeader),
			count: formatMessage(messages.countColumnHeader),
		};

		content = (
			<>
				<Description>
					{data?.totalUnresolvedIssues === 0 ? (
						<>
							{formatMessage(messages.descriptionWhenEmpty)}
							{hasCreateItem && (
								<CreateItemLink
									href={`${urlBuilder('typesOfWork')}?inlineCreate=true`}
									onClick={() =>
										fireSectionAnalytics('view', {
											inlineCreate: true,
										})
									}
									target={linksTarget}
								/>
							)}
						</>
					) : (
						<FormattedI18nMessage
							message={formatMessage(messages.description, {
								linkStart: '{linkStart}',
								linkEnd: '{linkEnd}',
							})}
							componentsMapping={{
								link: ({ children }) => (
									<AccessibleLink
										href={`${urlBuilder('typesOfWork')}?direction=ASC&sortBy=issuetype`}
										target={linksTarget}
										onClick={() =>
											fireSectionAnalytics('view', {
												inlineCreate: false,
											})
										}
										aria-label={formatMessage(messages.viewAllItemsLabel)}
									>
										{children}
									</AccessibleLink>
								),
							}}
						/>
					)}
				</Description>

				<PercentageBarTable
					loading={loadingNext}
					onScrollToEnd={fetchNext}
					headers={headers}
					label={formatMessage(messages.title)}
				>
					{data &&
						data.issueTypes.map(({ id, issueType, count }) => (
							<PercentageBarRow
								key={id}
								iconBefore={<IssueType src={issueType.iconUrl} alt="" />}
								itemCount={count}
								totalCount={data.totalUnresolvedIssues}
								name={issueType.name}
								linkUrl={`${urlBuilder('typesOfWork', true)}?filter=${encodeURIComponent(
									`type = '${issueType.name.split("'").join("\\'")}' AND ${OPEN_ISSUES_JQL}`,
								)}`}
								onRowLinkClick={() =>
									fireSectionAnalytics('viewWithFilter', {
										count,
									})
								}
								linkTarget={linksTarget}
								actions={
									expVal(
										'jwm_summary_page_inline_issue_types_change',
										'isInlineIssueTypesEnabled',
										false,
									) ? (
										<></>
									) : null
								}
							/>
						))}
				</PercentageBarTable>

				{hasManageTypesButton &&
					(expVal(
						'jwm_summary_page_inline_issue_types_change',
						'isInlineIssueTypesEnabled',
						false,
					) ? (
						<AddIssueTypesButton onClick={() => fireSectionAnalytics('addIssueTypes')} />
					) : (
						<ManageTypesButton onClick={() => fireSectionAnalytics('manageTypes')} />
					))}
			</>
		);
	}

	return (
		<UFOSegment name="business-summary-types-of-work">
			<Card
				title={formatMessage(messages.title)}
				isLoading={loading}
				testId="business-summary.ui.summary-view.types-of-work"
				onPointerEnter={startHoverTimer}
				onPointerLeave={stopHoverTimer}
			>
				{content}
			</Card>
		</UFOSegment>
	);
};

export default memo<SectionProps>(TypesOfWork);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Description = styled.p({
	margin: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IssueType = styled.img({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${ICON_SIZE}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${ICON_SIZE}px`,
	borderRadius: token('border.radius', '4px'),
});
