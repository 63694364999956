import React, { memo } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { Link } from '@atlassian/react-resource-router';
import { useSummaryFeatures } from '../../../../controllers/features-context';
import { Y_AXIS_WIDTH } from '../constants';
import type { XAxisDataPoint } from '../types';

type Props = {
	points: XAxisDataPoint[];
	onClick: (count: number) => void;
};

const XAxis = ({ points, onClick }: Props) => {
	const { linksTarget } = useSummaryFeatures();

	return (
		<AxisWrapper>
			{points.map(({ icon, filterUrl, name, count }, index) => (
				<AxisItem key={index}>
					{typeof icon === 'string' ? <Image alt="" src={icon} /> : icon}
					<PriorityLink href={filterUrl} target={linksTarget} onClick={() => onClick(count)}>
						{name}
					</PriorityLink>
				</AxisItem>
			))}
		</AxisWrapper>
	);
};

export default memo<Props>(XAxis);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AxisWrapper = styled.div({
	display: 'grid',
	gridAutoColumns: 'minmax(0, 1fr)',
	gridAutoFlow: 'column',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	marginLeft: `${Y_AXIS_WIDTH}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AxisItem = styled.div({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PriorityLink = styled(Link)({
	maxWidth: '100%',
	textAlign: 'center',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	fontWeight: 500,
	fontSize: '12px',
	marginTop: token('space.025', '2px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Image = styled.img({
	height: '24px',
	width: '24px',
});
