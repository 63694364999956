import React, {
	createContext,
	useMemo,
	useContext,
	type ReactNode,
	type ReactElement,
} from 'react';
import type { UrlBuilder } from '../../common/types';

type RequiredProps = {
	/**
	 * @deprecated delete DetailsComponent when cleaning up fun-1194_decouple_overview_summary_page
	 */
	DetailsComponent: ({ isSkeleton }: { isSkeleton: boolean }) => ReactElement | null;
	urlBuilder: UrlBuilder;
};

type OptionalProps = {
	featureView: 'summary' | 'summary-embed';
	/**
	 * @deprecated delete hasActivityFeed when cleaning up fun-1194_decouple_overview_summary_page
	 */
	hasActivityFeed: boolean;
	hasCreateItem: boolean;
	hasFeedbackFooter: boolean;
	hasHeader: boolean;
	hasInviteTeammateButton: boolean;
	hasManageTypesButton: boolean;
	/**
	 * @deprecated delete hasPrefetching when cleaning up fun-1194_decouple_overview_summary_page
	 */
	hasPrefetching: boolean;
	/**
	 * @deprecated delete hasRelatedProjects when cleaning up fun-1194_decouple_overview_summary_page
	 */
	hasRelatedProjects: boolean;
	hasShareButton: boolean;
	linksTarget: '_self' | '_blank';
};

type ContextProps = RequiredProps &
	Partial<OptionalProps> & {
		children: ReactNode;
	};

type FeaturesContext = RequiredProps & OptionalProps;

const defaultFeaturesContext: OptionalProps = {
	featureView: 'summary',
	/**
	 * @deprecated delete hasActivityFeed when cleaning up fun-1194_decouple_overview_summary_page
	 */
	hasActivityFeed: true,
	hasCreateItem: true,
	hasFeedbackFooter: true,
	hasHeader: true,
	hasInviteTeammateButton: true,
	hasManageTypesButton: true,
	/**
	 * @deprecated delete hasPrefetching when cleaning up fun-1194_decouple_overview_summary_page
	 */
	hasPrefetching: true,
	/**
	 * @deprecated delete hasRelatedProjects when cleaning up fun-1194_decouple_overview_summary_page
	 */
	hasRelatedProjects: true,
	hasShareButton: true,
	linksTarget: '_self',
};

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
const SummaryFeaturesContext = createContext<FeaturesContext>(null as any);

const useSummaryFeatures = () => useContext(SummaryFeaturesContext);

const SummaryFeaturesProvider = ({ children, ...props }: ContextProps) => {
	const value = useMemo(() => ({ ...defaultFeaturesContext, ...props }), [props]);

	return (
		<SummaryFeaturesContext.Provider value={value}>{children}</SummaryFeaturesContext.Provider>
	);
};

export default SummaryFeaturesProvider;
export { useSummaryFeatures };
