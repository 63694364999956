import React, { memo } from 'react';
import BusinessAppWrapper from '@atlassian/jira-business-app-wrapper/src/ui/app-wrapper/index.tsx';
import { ExperienceErrorBoundary } from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import SummaryView from '@atlassian/jira-business-summary/src/ui/summary-view/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	SCREEN,
} from '@atlassian/jira-product-analytics-bridge';
import { MAIN_EXPERIENCE } from './common/constants';
import { useSummaryFeatures } from './controllers/features-context';
import { SummaryProvider, PrefetchedSummaryProvider } from './controllers/summary-context';

export { projectUrlBuilderFactory, overviewUrlBuilderFactory } from './common/utils';
export { default as FeaturesProvider } from './controllers/features-context';
export { default as ProjectDetails } from './ui/summary-view/project-details';
export { default as OverviewDetails } from './ui/summary-view/overview-details';

const Summary = () => {
	const { hasPrefetching } = useSummaryFeatures();

	const view = <SummaryView />;

	return (
		<ExperienceErrorBoundary experience={MAIN_EXPERIENCE} shouldStartExperience>
			<BusinessAppWrapper withOverflow>
				<ContextualAnalyticsData objectType="summary" sourceType={SCREEN} sourceName="summary">
					{fg('fun-1194_decouple_overview_summary_page') || hasPrefetching ? (
						<PrefetchedSummaryProvider>{view}</PrefetchedSummaryProvider>
					) : (
						<SummaryProvider>{view}</SummaryProvider>
					)}

					<FireScreenAnalytics />
				</ContextualAnalyticsData>
			</BusinessAppWrapper>
		</ExperienceErrorBoundary>
	);
};

export default memo(Summary);
