import { useCallback, useEffect, useReducer } from 'react';
import { useJqlContext } from '@atlassian/jira-business-entity-common/src/controllers/jql-context/index.tsx';
import { fetchSummaryPageData } from '@atlassian/jira-business-summary-services/src/services/summary-data/index.tsx';
import type { SummaryPageDataType } from '@atlassian/jira-business-summary-services/src/services/summary-data/types.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';

const initialState = { data: undefined, loading: true, error: undefined } as const;

type HookResponseType = {
	data: SummaryPageDataType | null | undefined;
	loading: boolean;
	error: Error | undefined;
};

type Action =
	| { type: 'init' }
	| {
			type: 'success';
			value: SummaryPageDataType;
	  }
	| {
			type: 'error';
			value: Error;
	  };

const reducer = (state: HookResponseType, action: Action) => {
	switch (action.type) {
		case 'init':
			return initialState;
		case 'success':
			return { ...state, data: action.value, loading: false };
		case 'error':
			return { ...state, loading: false, error: action.value };
		default:
			return state;
	}
};

/**
 * @deprecated delete when cleaning up fun-1194_decouple_overview_summary_page
 */
export const useSummaryData = () => {
	const [{ data, loading, error }, dispatch] = useReducer(reducer, initialState);

	const { cloudId } = useTenantContext();
	const jqlContext = useJqlContext();

	const executeQuery = useCallback(async () => {
		dispatch({
			type: 'init',
		});

		try {
			const response = await fetchSummaryPageData({ cloudId, jqlContext });

			dispatch({
				type: 'success',
				value: response,
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (serviceError: any) {
			dispatch({
				type: 'error',
				value: serviceError,
			});
		}
	}, [cloudId, jqlContext]);

	useEffect(() => {
		executeQuery();
	}, [executeQuery]);

	return {
		loading,
		error,
		data,
	};
};
