import React, { memo, useMemo } from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import { token } from '@atlaskit/tokens';
import type { ActivityFeedItem } from '@atlassian/jira-business-summary-services/src/services/activity-feed/types.tsx';
import { formatDistanceWithLocale } from '@atlassian/jira-platform-utils-date-fns/src/main.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import { useSummaryFeatures } from '../../../../controllers/features-context';
import { transformTitle, transformContent } from './utils';

export type Props = {
	item: ActivityFeedItem;
	onClick: (target: 'user' | 'issue') => void;
};

const ActivityItem = ({ item, onClick }: Props) => {
	const locale = useLocale();

	const { linksTarget } = useSummaryFeatures();

	const title = useMemo(
		() => transformTitle(item, onClick, linksTarget),
		[item, onClick, linksTarget],
	);
	const content = useMemo(() => transformContent(item), [item]);

	const relativePublishedTime = useMemo(
		() => formatDistanceWithLocale(new Date(item.published), new Date(), locale, true),
		[item, locale],
	);

	return (
		<Wrapper>
			<FirstRow>
				<Avatar src={item.author.avatarUrl} name={item.author.name} />

				<Title>
					{title} <Published>{relativePublishedTime}</Published>
				</Title>
			</FirstRow>

			{content}
		</Wrapper>
	);
};

export default memo<Props>(ActivityItem);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.li({
	position: 'relative',
	margin: 0,
	padding: `${token('space.100', '8px')} 0`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FirstRow = styled.div({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Title = styled.span({
	marginLeft: token('space.100', '8px'),
	flex: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Published = styled.div({
	font: token('font.body.UNSAFE_small'),
	color: token('color.text.subtlest', '#626f86'),
});
