import React, { memo, useCallback } from 'react';
import Avatar from '@atlaskit/avatar';
import { OPEN_ISSUES_JQL } from '@atlassian/jira-business-summary-services/src/services/constants.tsx';
import type { AssigneeUserType } from '@atlassian/jira-business-summary-services/src/services/types.tsx';
import { FormattedI18nMessage } from '@atlassian/jira-formatted-i18n-message/src/ui/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import UFOSegment from '@atlassian/jira-ufo-segment';
import UFOLoadHold from '@atlassian/react-ufo/load-hold';
import type { SectionProps, UrlBuilder } from '../../../common/types';
import AccessibleLink from '../../../common/ui/accessible-link';
import Card from '../../../common/ui/card';
import CreateItemLink from '../../../common/ui/create-item-link';
import PercentageBarTable from '../../../common/ui/percentage-bar-table';
import PercentageBarRow from '../../../common/ui/percentage-bar-table/percentage-bar-row';
import { useSectionAnalytics } from '../../../common/utils';
import { useSummaryFeatures } from '../../../controllers/features-context';
import useIssuesByAssignee from '../../../controllers/paged-issues-by-assignee';
import InviteTeammate from './invite-teammate';
import messages from './messages';
import { CenteredSkeleton } from './skeleton/styled';
import { Description } from './styled';
import { getColor } from './utils';

const NO_ASSIGNEE_VALUE = 'EMPTY';

const getAssigneeFilterLink = (urlBuilder: UrlBuilder, user: AssigneeUserType | null) =>
	`${urlBuilder('workloadByAssignee', true)}?filter=${encodeURIComponent(
		`assignee = ${user?.accountId ?? NO_ASSIGNEE_VALUE} AND ${OPEN_ISSUES_JQL}`,
	)}`;

const WorkloadByAssignee = ({ position }: SectionProps) => {
	const { formatMessage } = useIntl();
	const { hasCreateItem, hasInviteTeammateButton, linksTarget, urlBuilder } = useSummaryFeatures();
	const { data, loading, fetchNext, loadingNext } = useIssuesByAssignee();

	const { startHoverTimer, stopHoverTimer, fireSectionAnalytics } = useSectionAnalytics(
		position,
		'workloadByAssignee',
	);

	const fireInviteUserAnalytics = useCallback(
		() => fireSectionAnalytics('inviteUser'),
		[fireSectionAnalytics],
	);

	let content;

	if (loading) {
		content = (
			<UFOLoadHold name="workload-by-assignee">
				<CenteredSkeleton testId="business-summary.ui.summary-view.workload-by-assignee.skeleton" />
			</UFOLoadHold>
		);
	} else {
		const isEmptyProject = data?.totalUnresolvedIssues === 0;
		const hasNoIssuesAssigned = data?.assignees.length === 1 && data?.assignees[0].user == null;

		const description = hasNoIssuesAssigned
			? messages.descriptionWhenNoIssuesAssigned
			: messages.description;

		const headers = {
			identifier: formatMessage(messages.assigneeColumnHeader),
			percentageBar: formatMessage(messages.workloadColumnHeader),
			count: formatMessage(messages.countColumnHeader),
		};

		content = (
			<>
				<Description>
					{isEmptyProject ? (
						<>
							{formatMessage(messages.descriptionWhenEmpty)}
							{hasCreateItem && (
								<>
									{' '}
									<CreateItemLink
										href={`${urlBuilder('workloadByAssignee')}?inlineCreate=true`}
										onClick={() =>
											fireSectionAnalytics('view', {
												inlineCreate: true,
											})
										}
										target={linksTarget}
									/>
								</>
							)}
						</>
					) : (
						<FormattedI18nMessage
							message={formatMessage(description, {
								linkStart: '{linkStart}',
								linkEnd: '{linkEnd}',
							})}
							componentsMapping={{
								link: ({ children }) => (
									<AccessibleLink
										href={`${urlBuilder('workloadByAssignee')}?direction=ASC&sortBy=assignee`}
										target={linksTarget}
										onClick={() =>
											fireSectionAnalytics('view', {
												inlineCreate: false,
											})
										}
									>
										{children}
									</AccessibleLink>
								),
							}}
						/>
					)}
				</Description>

				<PercentageBarTable
					loading={loadingNext}
					onScrollToEnd={fetchNext}
					headers={headers}
					label={formatMessage(messages.title)}
				>
					{data &&
						data.assignees.map(({ id, user, count }, index) => {
							const displayName = user?.displayName ?? formatMessage(messages.unassigned);

							return (
								<PercentageBarRow
									key={id}
									iconBefore={<Avatar src={user?.avatarUrl} name={displayName} />}
									itemCount={count}
									totalCount={data.totalUnresolvedIssues}
									barColor={user ? getColor(index) : undefined}
									name={displayName}
									linkUrl={getAssigneeFilterLink(urlBuilder, user)}
									onRowLinkClick={() =>
										fireSectionAnalytics('viewWithFilter', {
											count,
											isUnassigned: !user,
										})
									}
									linkTarget={linksTarget}
								/>
							);
						})}

					{isEmptyProject && (
						<PercentageBarRow
							iconBefore={<Avatar name={formatMessage(messages.unassigned)} />}
							itemCount={0}
							totalCount={0}
							name={formatMessage(messages.unassigned)}
							linkUrl={getAssigneeFilterLink(urlBuilder, null)}
							onRowLinkClick={() =>
								fireSectionAnalytics('viewWithFilter', {
									count: 0,
									isUnassigned: true,
								})
							}
							linkTarget={linksTarget}
						/>
					)}
				</PercentageBarTable>

				{hasInviteTeammateButton && <InviteTeammate onClick={fireInviteUserAnalytics} />}
			</>
		);
	}

	return (
		<UFOSegment name="business-summary-workload-by-assignee">
			<Card
				title={formatMessage(messages.title)}
				isLoading={loading}
				testId="business-summary.ui.summary-view.workload-by-assignee"
				onPointerEnter={startHoverTimer}
				onPointerLeave={stopHoverTimer}
			>
				{content}
			</Card>
		</UFOSegment>
	);
};

export default memo<SectionProps>(WorkloadByAssignee);
