import React, { memo, useEffect } from 'react';
import { styled } from '@compiled/react';
import { xcss, Box } from '@atlaskit/primitives';
import { BusinessDocumentTitle } from '@atlassian/jira-business-entity-common/src/utils/document-title/index.tsx';
import { ErrorPage } from '@atlassian/jira-business-error-page';
import {
	ExperienceErrorBoundary,
	ExperienceSuccess,
	ExperienceFailed,
} from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import { REQUEST_START, REQUEST_END } from '@atlassian/jira-business-performance/src/constants.tsx';
import {
	PageLoadMetrics,
	RenderStartMark,
} from '@atlassian/jira-business-performance/src/ui/page-load/index.tsx';
import { markViewMetric } from '@atlassian/jira-business-performance/src/ui/page-load/utils.tsx';
import { JWMSpaStatePageReady } from '@atlassian/jira-business-spa-state-page-ready/src/ui/index.tsx';
import { Tokens } from '@atlassian/jira-custom-theme-constants/src/constants.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	MAIN_EXPERIENCE,
	ACTIVITY_FEED_EXPERIENCE,
	RELATED_PROJECTS_EXPERIENCE,
	CARD_MARGIN,
} from '../../common/constants';
import { ErrorCard } from '../../common/ui/card';
import { useSummaryFeatures } from '../../controllers/features-context';
import { useSummaryContext } from '../../controllers/summary-context';
import ActivityFeed from './activity-feed';
import BreakdownByPriority from './breakdown-by-priority';
import FeedbackBanner from './feedback-banner';
import SummaryHeader from './header';
import messages from './messages';
import OverviewItems from './overview-items';
import ProgressByStatus from './progress-by-status';
import ProjectDetails from './project-details/index.tsx';
import RelatedProjects from './related-projects';
import TypesOfWork from './types-of-work';
import WorkloadByAssignee from './workload-by-assignee';

const cardFallback = () => <ErrorCard />;

const SummaryView = () => {
	const { formatMessage } = useIntl();
	const {
		DetailsComponent,
		featureView,
		hasActivityFeed,
		hasFeedbackFooter,
		hasHeader,
		hasRelatedProjects,
	} = useSummaryFeatures();

	const { data, loading, error } = useSummaryContext();
	const isReady = data != null && !loading && !error;

	useEffect(() => {
		if (loading) {
			markViewMetric('summary', REQUEST_START);
		} else {
			markViewMetric('summary', REQUEST_END);
		}
	}, [loading]);

	const pageHeading = formatMessage(messages.summaryHeading);

	if (error != null) {
		return (
			<>
				<ErrorPage error={error} />
				<ExperienceFailed experience={MAIN_EXPERIENCE} error={error} />
			</>
		);
	}

	const header = (
		<Box xcss={HeaderWrapperStyles}>
			{hasHeader && <SummaryHeader isLoading={loading} />}

			{fg('fun-1194_decouple_overview_summary_page') ? (
				<ProjectDetails isSkeleton={loading} />
			) : (
				<DetailsComponent isSkeleton={loading} />
			)}
		</Box>
	);

	let position = 0;

	// eslint-disable-next-line no-return-assign
	return (
		<>
			<RenderStartMark view="summary" loading={loading} />
			<BusinessDocumentTitle pageHeading={pageHeading} />
			<ResponsiveContainer>
				{header}
				<OverviewItems />
				<CardGrid>
					<ProgressByStatus position={(position += 1)} />

					{(fg('fun-1194_decouple_overview_summary_page') || hasActivityFeed) && (
						<ExperienceErrorBoundary
							experience={ACTIVITY_FEED_EXPERIENCE}
							shouldStartExperience
							fallback={cardFallback}
						>
							<ActivityFeed position={(position += 1)} />
						</ExperienceErrorBoundary>
					)}

					<BreakdownByPriority position={(position += 1)} />

					<TypesOfWork position={(position += 1)} />

					<WorkloadByAssignee position={(position += 1)} />

					{(fg('fun-1194_decouple_overview_summary_page') || hasRelatedProjects) && (
						<ExperienceErrorBoundary
							experience={RELATED_PROJECTS_EXPERIENCE}
							shouldStartExperience
							fallback={cardFallback}
						>
							<RelatedProjects position={(position += 1)} />
						</ExperienceErrorBoundary>
					)}
				</CardGrid>

				{hasFeedbackFooter && (
					<footer>
						<FeedbackBanner isLoading={loading} />
					</footer>
				)}
			</ResponsiveContainer>

			{isReady && (
				<>
					<PageLoadMetrics view={featureView} />
					<ExperienceSuccess experience={MAIN_EXPERIENCE} />
				</>
			)}
			{!loading && <JWMSpaStatePageReady />}
		</>
	);
};

export default memo<{}>(SummaryView);

const HeaderWrapperStyles = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	background: Tokens.ELEVATION_SURFACE_CONTRAST,
	borderRadius: 'border.radius.200',
	paddingLeft: 'space.400',
	paddingRight: 'space.400',
	paddingTop: 'space.300',
	paddingBottom: 'space.100',
	marginTop: 'space.200',
	marginBottom: 'space.100',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ResponsiveContainer = styled.div({
	maxWidth: '1096px',
	width: '100%',
	alignSelf: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardGrid = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	margin: `0 -${CARD_MARGIN}px`,
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'center',
});
