import React, { type PropsWithChildren } from 'react';
import { styled } from '@compiled/react';
import Spinner from '@atlaskit/spinner';
import { N200, N0, N30 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useScrollToEnd } from '@atlassian/jira-business-scroll-to-end';
import { IDENTIFIER_COLUMN_WIDTH, COUNT_COLUMN_WIDTH } from './percentage-bar-row';

type Props = {
	loading: boolean;
	headers: {
		identifier: string;
		percentageBar: string;
		count: string;
	};
	onScrollToEnd: () => void;
	label?: string;
};

const PercentageBarTable = ({
	headers,
	onScrollToEnd,
	loading,
	children,
	label,
}: PropsWithChildren<Props>) => {
	const { onScroll } = useScrollToEnd({
		onScrollToEnd,
		isDisabled: loading,
		options: {
			onEndReachedThreshold: 50,
		},
	});

	const { identifier, percentageBar, count } = headers;

	return (
		<ScrollableWrapper onScroll={onScroll}>
			<table aria-label={label}>
				<TableHead>
					<tr>
						<IdentifierColumnHeader>{identifier}</IdentifierColumnHeader>
						<BarColumnHeader>{percentageBar}</BarColumnHeader>
						<CountColumnHeader>{count}</CountColumnHeader>
					</tr>
				</TableHead>
				<TableBody>{children}</TableBody>
			</table>

			{loading && (
				<SpinnerWrapper>
					<Spinner testId="business-summary.common.ui.percentage-bar-table.spinner" />
				</SpinnerWrapper>
			)}
		</ScrollableWrapper>
	);
};

export default PercentageBarTable;

const TABLE_HEADER_HEIGHT = 24;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ScrollableWrapper = styled.div({
	margin: `${token('space.200', '16px')} ${token('space.negative.300', '-24px')} 0`,
	padding: `0 ${token('space.300', '24px')}`,
	overflowY: 'auto',
	flex: '0 1 auto',
	background: `linear-gradient(${token('elevation.surface.raised', N0)}, ${token(
		'elevation.surface.raised',
		N0,
	)}) 0 ${TABLE_HEADER_HEIGHT}px / 100% 2px no-repeat local border-box, linear-gradient(${token(
		'elevation.surface.raised',
		N0,
	)}, ${token(
		'elevation.surface.raised',
		N0,
	)}) 0 100% / 100% 2px no-repeat local border-box, linear-gradient(${token(
		'color.border',
		N30,
	)}, ${token(
		'color.border',
		N30,
	)}) 0 ${TABLE_HEADER_HEIGHT}px / 100% 2px no-repeat scroll content-box, linear-gradient(${token(
		'color.border',
		N30,
	)}, ${token('color.border', N30)}) 0 100% / 100% 2px no-repeat scroll content-box`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TableHead = styled.thead({
	border: 'none',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TableBody = styled.tbody({
	border: 'none',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BarColumnHeader = styled.th({
	lineHeight: '16px',
	fontWeight: 500,
	color: token('color.text.subtlest', N200),
	position: 'sticky',
	top: 0,
	background: token('elevation.surface.raised', N0),
	zIndex: 1,
	boxSizing: 'border-box',
	height: `${TABLE_HEADER_HEIGHT}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IdentifierColumnHeader = styled(BarColumnHeader)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${IDENTIFIER_COLUMN_WIDTH}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CountColumnHeader = styled(BarColumnHeader)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${COUNT_COLUMN_WIDTH}px`,
	textAlign: 'right',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinnerWrapper = styled.div({
	display: 'grid',
	justifyContent: 'center',
	margin: token('space.150', '12px'),
});
