/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useReducer, useRef, type Reducer } from 'react';
import { ABORTED } from '@atlassian/jira-business-abortable';

type Data<T> = T[];

type PageInfo = {
	hasNextPage: boolean;
	endCursor: string | null;
};

export type ResponseData<T> = {
	data: Data<T>;
	pageInfo: PageInfo;
};

type InitialData<T> = ResponseData<T>;

type FetchMode = 'append' | 'replace';

type Options<T> = {
	initialData?: InitialData<T>;
	fetchMode?: FetchMode;
};

type State<T, E> = {
	data: Data<T> | null;
	pageInfo: PageInfo | null;
	loading: boolean;
	loadingNext: boolean;
	error: E | null;
	fetchMode: FetchMode;
};

type Action<T, E> =
	| {
			type: 'reset';
			value: InitialData<T> | undefined;
	  }
	| {
			type: 'fetch';
	  }
	| {
			type: 'fetch-next';
	  }
	| {
			type: 'success';
			value: ResponseData<T>;
	  }
	| {
			type: 'failure';
			value: E;
	  };

export type FetchCallback<T> = (cursor: string | null, args?: any) => Promise<ResponseData<T>>;

export type Fetch<T> = (args?: any) => Promise<ResponseData<T> | null>;

export type FetchNext<T> = (args?: any) => Promise<ResponseData<T> | null>;

type DoFetch<T> = (action: 'fetch' | 'fetch-next', args?: any) => Promise<ResponseData<T> | null>;

export type Reset<T> = (initialData?: InitialData<T>) => void;

export type ReturnValue<T, E> = {
	data: Data<T> | null;
	loading: boolean;
	loadingNext: boolean;
	error: E | null;
	hasNextPage: boolean;
	fetch: Fetch<T>;
	fetchNext: FetchNext<T>;
	reset: Reset<T>;
};

function init<T, E>(options: Options<T>): State<T, E> {
	const fetchMode = options.fetchMode || 'append';

	return {
		data: null,
		pageInfo: null,
		...options.initialData,
		loading: false,
		loadingNext: false,
		error: null,
		fetchMode,
	};
}

function resetState<T, E>(state: State<T, E>, initialData?: InitialData<T>): State<T, E> {
	return {
		...state,
		data: null,
		pageInfo: null,
		...initialData,
		loading: false,
		loadingNext: false,
		error: null,
	};
}

function reducer<T, E>(state: State<T, E>, action: Action<T, E>): State<T, E> {
	switch (action.type) {
		case 'reset':
			return resetState<T, E>(state, action.value);
		case 'fetch':
			return { ...resetState<T, E>(state), loading: true };
		case 'fetch-next': {
			if (state.pageInfo == null) {
				return { ...state, loading: true };
			}
			return { ...state, loadingNext: true };
		}
		case 'success':
			return {
				...state,
				data:
					state.data == null || state.fetchMode === 'replace'
						? action.value.data
						: [...state.data, ...action.value.data],
				pageInfo: action.value.pageInfo,
				loading: false,
				loadingNext: false,
				error: null,
			};
		case 'failure':
			return {
				...state,
				loading: false,
				loadingNext: false,
				error: action.value,
			};
		default:
			break;
	}
	return state;
}

export function usePagination<T, E>(
	fetchCallback: FetchCallback<T>,
	options: Options<T> = Object.freeze({}),
): ReturnValue<T, E> {
	const [state, dispatch] = useReducer<Reducer<State<T, E>, Action<T, E>>, Options<T>>(
		reducer,
		options,
		init,
	);

	const stateRef = useRef<State<T, E>>(state);
	stateRef.current = state;

	const doFetch: DoFetch<T> = useCallback(
		(action, args) => {
			dispatch({ type: action });

			const cursor = action === 'fetch-next' ? stateRef.current.pageInfo?.endCursor ?? null : null;

			return fetchCallback(cursor, args)
				.then((response) => {
					dispatch({ type: 'success', value: response });

					return response;
				})
				.catch((error: E | typeof ABORTED) => {
					// if the request was aborted, keep showing the UI as loading
					if (error === ABORTED) {
						return null;
					}

					dispatch({ type: 'failure', value: error });

					throw error;
				});
		},
		[fetchCallback],
	);

	const fetch: Fetch<T> = useCallback((args) => doFetch('fetch', args), [doFetch]);

	const fetchNext: FetchNext<T> = useCallback(
		(args) => {
			if (
				stateRef.current.loading ||
				stateRef.current.error != null ||
				stateRef.current.pageInfo?.hasNextPage === false
			) {
				return Promise.resolve(null);
			}

			return doFetch('fetch-next', args);
		},
		[doFetch],
	);

	const reset: Reset<T> = useCallback(
		(resetData) => dispatch({ type: 'reset', value: resetData }),
		[],
	);

	const { data, loading, loadingNext, error, pageInfo } = state;
	const hasNextPage = pageInfo?.hasNextPage || false;

	return { data, loading, loadingNext, error, hasNextPage, fetch, fetchNext, reset };
}
