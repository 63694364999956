import { useCallback } from 'react';
import { useJqlContext } from '@atlassian/jira-business-entity-common/src/controllers/jql-context/index.tsx';
import { GraphQLErrors } from '@atlassian/jira-business-graphql-errors';
import {
	OPEN_ISSUES_JQL,
	PAGED_DATA_PAGE_SIZE,
} from '@atlassian/jira-business-summary-services/src/services/constants.tsx';
import type { PagedIssuesByTypeType } from '@atlassian/jira-business-summary-services/src/services/types.tsx';
import { transformIssuesByTypeData } from '@atlassian/jira-business-summary-services/src/services/utils.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { ISSUES_BY_TYPE_QUERY } from './gql';

const QUERY_NAME = 'IssuesByTypeData';

type Fetch = (after: string | null) => Promise<PagedIssuesByTypeType>;

const useFetchIssuesByType = () => {
	const jqlContext = useJqlContext();

	const handleError = useCallback((error: Error) => {
		fireErrorAnalytics({
			meta: {
				id: QUERY_NAME,
				packageName: 'jiraBusinessSummary',
				teamName: 'wanjel',
			},
			error,
			sendToPrivacyUnsafeSplunk: true,
		});

		throw error;
	}, []);

	const fetch: Fetch = useCallback(
		(after) =>
			performPostRequest(`/rest/gira/1/?operation=${QUERY_NAME}`, {
				body: JSON.stringify({
					query: ISSUES_BY_TYPE_QUERY,
					variables: {
						jql: `${jqlContext} AND ${OPEN_ISSUES_JQL}`,
						pageSize: PAGED_DATA_PAGE_SIZE,
						after,
					},
				}),
			})
				.then((response) => {
					if (response.errors != null) {
						throw new GraphQLErrors(response.errors);
					}

					return transformIssuesByTypeData(response.data.issuesByType);
				})
				.catch(handleError),
		[handleError, jqlContext],
	);

	return fetch;
};

export default useFetchIssuesByType;
