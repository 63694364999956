import React, { memo } from 'react';
import { styled, css } from '@compiled/react';
import Button from '@atlaskit/button/standard-button';
import AddIcon from '@atlaskit/icon/glyph/add';
import { N20A, N40A, N200, N300, N800, B50, B200, B300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { getJwmCreateProjectDrawerOptions } from '@atlassian/jira-business-common/src/common/utils/create-jwm-project-drawer/index.tsx';
import {
	ExperienceSuccess,
	ExperienceFailed,
} from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import { borderRadius, gridSize, fontSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { ProjectCreateDrawerConsumer } from '@atlassian/jira-project-create-drawer-context';
import { useProjectCreatePermissionsResource } from '@atlassian/jira-router-resources-project-permissions/src/services/project-permissions/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment';
import { Link } from '@atlassian/react-resource-router';
import UFOLoadHold from '@atlassian/react-ufo/load-hold';
import { RELATED_PROJECTS_EXPERIENCE } from '../../../common/constants';
import type { SectionProps } from '../../../common/types';
import Card, { ErrorCard } from '../../../common/ui/card';
import { useSectionAnalytics } from '../../../common/utils';
import { useSummaryFeatures } from '../../../controllers/features-context';
import { useSummaryContext } from '../../../controllers/summary-context';
import { useRelatedProjects } from '../../../services/related-projects';
import EmptyIllustration from './empty-illustration';
import messages from './messages';
import Skeleton from './skeleton';

const PROJECT_CREATE_OPTIONS = getJwmCreateProjectDrawerOptions('jwmSummaryPage');

const RelatedProjects = ({ position }: SectionProps) => {
	const { formatMessage } = useIntl();
	const { loading: isSummaryDataLoading } = useSummaryContext();
	const { linksTarget } = useSummaryFeatures();
	const { data, loading: isRelatedProjectsLoading, error } = useRelatedProjects();
	const { data: permissions } = useProjectCreatePermissionsResource();
	const isLoading = isSummaryDataLoading || isRelatedProjectsLoading;

	const { startHoverTimer, stopHoverTimer, fireSectionAnalytics } = useSectionAnalytics(
		position,
		'relatedProjects',
	);

	if (error != null) {
		return (
			<>
				<ErrorCard />
				<ExperienceFailed experience={RELATED_PROJECTS_EXPERIENCE} error={error} />
			</>
		);
	}

	const canCreateProject = Boolean(permissions?.canCreateProject);
	const createProjectRoute = `/jira/create-project?templateId=${PROJECT_CREATE_OPTIONS.defaultSelectedTemplateKey}`;
	let content = null;

	if (isLoading) {
		content = (
			<UFOLoadHold name="related-projects">
				<CenteredSkeleton testId="business-summary.ui.summary-view.related-projects.skeleton" />
			</UFOLoadHold>
		);
	} else if (data != null && data.length === 0) {
		content = (
			<>
				<Description>{formatMessage(messages.descriptionWhenEmpty)}</Description>

				<EmptyState withCreateProject={canCreateProject}>
					<EmptyStateIllustration />

					{canCreateProject && (
						<ProjectCreateDrawerConsumer>
							{({ methods: { open } }) => (
								<Button
									appearance="primary"
									onClick={() => {
										linksTarget === '_blank'
											? window.open(createProjectRoute, '_blank')
											: open(PROJECT_CREATE_OPTIONS);
										fireSectionAnalytics('createProject', {
											isEmpty: true,
										});
									}}
									testId="business-summary.ui.summary-view.related-projects.create-project-button-empty"
								>
									{formatMessage(messages.createProject)}
								</Button>
							)}
						</ProjectCreateDrawerConsumer>
					)}

					<Button
						href="/jira/projects"
						target={linksTarget}
						component={Link}
						onClick={() =>
							fireSectionAnalytics('projectDirectory', {
								isEmpty: true,
							})
						}
					>
						{formatMessage(messages.viewAllProjects)}
					</Button>
				</EmptyState>

				<ExperienceSuccess experience={RELATED_PROJECTS_EXPERIENCE} />
			</>
		);
	} else if (data != null) {
		content = (
			<>
				<Description>{formatMessage(messages.description)}</Description>

				<Grid data-testid="business-summary.ui.summary-view.related-projects.grid">
					{canCreateProject && (
						<ProjectCreateDrawerConsumer>
							{({ methods: { open } }) => (
								<CreateProject
									onClick={() => {
										linksTarget === '_blank'
											? window.open(createProjectRoute, '_blank')
											: open(PROJECT_CREATE_OPTIONS);
										fireSectionAnalytics('createProject', {
											isEmpty: false,
										});
									}}
									data-testid="business-summary.ui.summary-view.related-projects.create-project-button"
								>
									<CreateProjectIcon data-icon>
										<AddIcon label="" />
									</CreateProjectIcon>
									{formatMessage(messages.createProject)}
								</CreateProject>
							)}
						</ProjectCreateDrawerConsumer>
					)}

					{data.map(({ id, avatarUrl, name, key, url, projectTypeKey }, index) => (
						<Project
							data-testid="business-summary.ui.summary-view.related-projects.project"
							href={url}
							target={linksTarget}
							key={id}
							onClick={() =>
								fireSectionAnalytics('relatedProject', {
									projectTypeKey,
									// Ensure position count starts at 1
									resultPosition: index + 1,
								})
							}
						>
							<ProjectAvatar src={`${avatarUrl}?size=xxlarge`} alt="" />

							<ProjectDetails>
								<Tooltip content={name}>
									<ProjectName>{name}</ProjectName>
								</Tooltip>
								{key}
							</ProjectDetails>
						</Project>
					))}
				</Grid>

				<AllProjects
					href="/jira/projects"
					target={linksTarget}
					onClick={() =>
						fireSectionAnalytics('projectDirectory', {
							isEmpty: false,
						})
					}
				>
					{formatMessage(messages.viewAllProjects)}
				</AllProjects>

				<ExperienceSuccess experience={RELATED_PROJECTS_EXPERIENCE} />
			</>
		);
	}

	return (
		<UFOSegment name="business-summary-related-projects">
			<Card
				title={formatMessage(messages.title)}
				isLoading={isLoading}
				testId="business-summary.ui.summary-view.related-projects"
				onPointerEnter={startHoverTimer}
				onPointerLeave={stopHoverTimer}
			>
				{content}
			</Card>
		</UFOSegment>
	);
};

export default memo<SectionProps>(RelatedProjects);

const buttonStyles = css({
	display: 'flex',
	alignItems: 'center',
	padding: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	fontSize: `${fontSize}px`,
	minWidth: 0,
	'&:hover, &:focus, &:active': {
		textDecoration: 'none',
		backgroundColor: token('color.background.neutral.subtle.hovered', N20A),
	},
});

const iconStyles = css({
	flex: 'none',
	boxSizing: 'border-box',
	height: '48px',
	width: '48px',
	marginRight: token('space.150', '12px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CenteredSkeleton = styled(Skeleton)({
	flex: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Description = styled.p({
	margin: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Grid = styled.div({
	display: 'grid',
	gap: token('space.100', '8px'),
	gridTemplateColumns: '1fr 1fr',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	margin: `${token('space.100', '8px')} -${gridSize * 2}px`,
	padding: token('space.100', '8px'),
	overflowY: 'auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const CreateProject = styled.button(buttonStyles, {
	color: token('color.text', N800),
	cursor: 'pointer',
	background: 'none',
	border: 'none',
	fontWeight: 600,
	'--icon-bg-color': token('color.background.neutral', N20A),
	'--icon-color': token('color.icon.subtle', N200),
	'--icon-border': `1px dashed ${token('color.border', N40A)}`,
	'&:hover, &:focus, &:active': {
		'--icon-bg-color': token('color.background.selected', B50),
		'--icon-color': token('color.text.selected', B300),
		'--icon-border': `1px dashed ${token('color.border.focused', B200)}`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const CreateProjectIcon = styled.div(iconStyles, {
	color: 'var(--icon-color)',
	backgroundColor: 'var(--icon-bg-color)',
	border: 'var(--icon-border)',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${gridSize}px`,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const Project = styled(Link)(buttonStyles);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const ProjectAvatar = styled.img(iconStyles, {
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRadius: `${borderRadius}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProjectDetails = styled.div({
	color: token('color.text.subtlest', N300),
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProjectName = styled.div({
	color: token('color.text', N800),
	fontWeight: 600,
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const AllProjects = styled(Link)({
	alignSelf: 'flex-start',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyState = styled.div<{ withCreateProject?: boolean }>({
	flex: 1,
	alignSelf: 'center',
	display: 'grid',
	gap: `${token('space.400', '32px')} ${token('space.100', '8px')}`,
	alignContent: 'center',
	justifyItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	gridTemplateAreas: ({ withCreateProject }) =>
		withCreateProject ? "'illustration illustration' '. .'" : "'illustration' '.'",
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyStateIllustration = styled(EmptyIllustration)({
	gridArea: 'illustration',
});
