import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	title: {
		id: 'business-summary.summary-view.progress-by-status.title',
		defaultMessage: 'Status overview',
		description: 'Title of the progress by status summary section.',
	},
	description: {
		id: 'business-summary.summary-view.progress-by-status.description',
		defaultMessage:
			'Get a snapshot of the status of your items. {linkStart}View all items{linkEnd}',
		description: 'Description of the progress by status summary section.',
	},
	viewAllItemsLabel: {
		id: 'business-summary.summary-view.progress-by-status.view-all-items-label',
		defaultMessage: 'View all issues with the status listed in the chart',
		description:
			'Description of the link that leads to a page that shows issues provided on the pie chart.',
	},
	descriptionWhenEmpty: {
		id: 'business-summary.summary-view.progress-by-status.description-when-empty',
		defaultMessage:
			"You'll need to create a few items for your project to get a status overview on the team's progress.",
		description: 'Description of the progress by status summary section when the project is empty.',
	},
	tableCountersLabel: {
		id: 'business-summary.summary-view.progress-by-status.table-counters-label',
		defaultMessage:
			'{issuesCount, number} {issuesCount, plural, one {issue} other {issues}}, {percentage}% with the {status} status',
		description: 'Label for the items counter in the progress by status table.',
	},
	totalCountWithLabel: {
		id: 'business-summary.summary-view.progress-by-status.total-count-with-label',
		defaultMessage: 'Total {issuesCount, number} {issuesCount, plural, one {issue} other {issues}}',
		description: 'Label for the total number of items in the project.',
	},

	total: {
		id: 'business-summary.summary-view.progress-by-status.total',
		defaultMessage: 'Total',
		description: 'Label for the total number of items in the project',
	},
});
