import React, { memo, useCallback } from 'react';
import { styled } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { R400, N30A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import {
	ExperienceSuccess,
	ExperienceFailed,
} from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import { ThemedLink } from '@atlassian/jira-business-theme-components/src/ui/ThemedLink.tsx';
import { ThemedSpinner } from '@atlassian/jira-business-theme-components/src/ui/ThemedSpinner.tsx';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { Tokens } from '@atlassian/jira-custom-theme-constants/src/constants.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { PROJECT_DETAILS_EXPERIENCE } from '../../../../common/constants';
import { useSummaryFeatures } from '../../../../controllers/features-context';
import type { ProjectMeta } from '../../../../services/project-meta/types';
import messages from './messages';

export type Props = {
	data: ProjectMeta | undefined | null;
	error: Error | undefined;
	loading: boolean;
};

const Content = ({ data, error, loading }: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { linksTarget } = useSummaryFeatures();

	const onProjectLeadClick = useCallback(
		() => fireUIAnalytics(createAnalyticsEvent({}), 'link clicked', 'summaryProjectLead'),
		[createAnalyticsEvent],
	);

	if (loading) {
		return (
			<LoadingPill>
				<ThemedSpinner size="medium" />
			</LoadingPill>
		);
	}

	if (error || data == null) {
		return (
			<ErrorPill>
				<ErrorIcon label="" primaryColor={token('color.icon.danger', R400)} />
				<ErrorMessage>{formatMessage(messages.errorMessage)}</ErrorMessage>
				{error && <ExperienceFailed experience={PROJECT_DETAILS_EXPERIENCE} error={error} />}
			</ErrorPill>
		);
	}

	return (
		<Pill>
			<LeadSection>
				<Avatar src={data.lead.avatarUrl} size="large" />

				<LeadDetails>
					<ThemedLink
						href={`/secure/ViewProfile.jspa?accountId=${data.lead.accountId}`}
						target={linksTarget}
						data-testid="business-summary.ui.summary-view.project-details.content.lead-profile-link"
						onClick={onProjectLeadClick}
					>
						{data.lead.displayName}
					</ThemedLink>

					<Heading>{formatMessage(messages.projectLead)}</Heading>
				</LeadDetails>
			</LeadSection>

			<KeySection>
				<Heading>{formatMessage(messages.projectKey)}</Heading>

				<span data-testid="business-summary.ui.summary-view.project-details.content.project-key">
					{data.key}
				</span>
			</KeySection>

			<ExperienceSuccess experience={PROJECT_DETAILS_EXPERIENCE} />
		</Pill>
	);
};

export default memo<Props>(Content);

const PILL_HEIGHT = 64;
const AVATAR_BORDER_WIDTH = 2;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Heading = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	color: Tokens.COLOR_TEXT_SUBTLE,
	font: token('font.body.UNSAFE_small'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Section = styled.div({
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const PillBase = styled.div({
	height: `${PILL_HEIGHT}px`,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: `${PILL_HEIGHT / 2}px`,
	boxSizing: 'border-box',
	backgroundColor: token('color.background.neutral', N30A),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Pill = styled(PillBase)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	padding: `0 ${PILL_HEIGHT / 2 + gridSize}px 0 ${gridSize + AVATAR_BORDER_WIDTH * 2}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LeadSection = styled(Section)({
	alignItems: 'center',
	flexDirection: 'row',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LeadDetails = styled.div({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	marginLeft: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const KeySection = styled(Section)({
	flex: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderLeft: `1px solid ${Tokens.COLOR_BORDER}`,
	marginLeft: token('space.200', '16px'),
	paddingLeft: token('space.200', '16px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LoadingPill = styled(PillBase)({
	width: `${PILL_HEIGHT}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorPill = styled(PillBase)({
	padding: `0 ${token('space.500', '40px')}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ErrorMessage = styled.span({
	marginLeft: token('space.050', '4px'),
});
