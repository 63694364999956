import { useCallback, useEffect, useReducer } from 'react';
import { useProject } from '@atlassian/jira-business-entity-project-hook';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { ProjectMeta, HookResponseType, HookState } from './types';
import { transformData } from './utils';

const initialState: HookState = { data: undefined, loading: false, error: undefined };

type Action =
	| {
			type: 'init';
	  }
	| {
			type: 'fetch';
	  }
	| {
			type: 'success';
			value: ProjectMeta;
	  }
	| {
			type: 'error';
			value: Error;
	  };

const reducer = (state: HookState, action: Action) => {
	switch (action.type) {
		case 'init':
			return initialState;
		case 'fetch':
			return { ...initialState, loading: true };
		case 'success':
			return { ...state, data: action.value, loading: false };
		case 'error':
			return { ...state, loading: false, error: action.value };
		default:
			return state;
	}
};

const useProjectMeta = (): HookResponseType => {
	const [{ data, loading, error }, dispatch] = useReducer(reducer, initialState);
	const project = useProject();
	const projectKey = project.key;

	const fetch = useCallback(async () => {
		dispatch({
			type: 'fetch',
		});

		try {
			const response = await fetchJson(`/rest/api/3/project/${projectKey}?expand=lead`);

			dispatch({
				type: 'success',
				value: transformData(response),
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (restError: any) {
			dispatch({ type: 'error', value: restError });

			fireErrorAnalytics({
				meta: {
					id: 'ProjectMeta',
					packageName: 'jiraBusinessSummary',
					teamName: 'wanjel',
				},
				error: restError,
				sendToPrivacyUnsafeSplunk: true,
			});
		}
	}, [projectKey]);

	useEffect(
		() =>
			// reset the state when the project changes
			dispatch({
				type: 'init',
			}),
		[projectKey],
	);

	return {
		fetch,
		data,
		loading,
		error,
	};
};

export default useProjectMeta;
