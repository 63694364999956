import React, { memo } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { useBusinessEntity } from '@atlassian/jira-business-entity-common/src/controllers/business-entity-context/index.tsx';
import { Tokens } from '@atlassian/jira-custom-theme-constants/src/constants.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current/src/main.tsx';
import type { User } from '@atlassian/jira-shared-types/src/rest/jira/user.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import { useSummaryFeatures } from '../../../controllers/features-context';
import messages from './messages';
import ShareButton from './share-button';
import SummaryHeaderSkeleton from './skeleton';
import { getTimeBasedGreeting } from './utils';

type Props = {
	isLoading: boolean;
};

const SummaryHeader = ({ isLoading }: Props) => {
	const { data: currentUserData, loading: isUserDataLoading } = useCurrentUser();
	const { data: entity } = useBusinessEntity();
	const { hasShareButton } = useSummaryFeatures();

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const userName = (currentUserData?.user as User)?.displayName;

	const { formatMessage } = useIntl();
	const { emoji, message } = getTimeBasedGreeting();

	const isSkeleton = isLoading || isUserDataLoading;

	const greeting = (
		<SummaryTitle>{`${formatMessage(message, {
			userName,
		})} ${emoji}`}</SummaryTitle>
	);

	const withShareButton = (
		<SummaryTitleWrapper>
			<SummaryTitleSideWrapper />
			{greeting}
			<SummaryTitleSideWrapper>
				{hasShareButton && !isVisualRefreshEnabled() && !fg('common_project_actions_menu') && (
					<ShareButton />
				)}
			</SummaryTitleSideWrapper>
		</SummaryTitleWrapper>
	);

	return (
		<UFOSegment name="business-summary-header">
			<SummaryHeaderWrapper>
				{isSkeleton ? (
					<SummaryHeaderSkeleton testId="business-summary.ui.summary-view.header.skeleton" />
				) : (
					withShareButton
				)}
				<SummarySubTitle isSkeleton={isSkeleton}>
					{formatMessage(messages.description, { containerName: entity?.name })}
				</SummarySubTitle>
			</SummaryHeaderWrapper>
		</UFOSegment>
	);
};

export default memo<Props>(SummaryHeader);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SummaryHeaderWrapper = styled.header({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	textAlign: 'center',
	marginBottom: token('space.050', '4px'),
	position: 'relative',
	width: '100%',
	flex: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SummaryTitle = styled.h3({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	color: Tokens.COLOR_TEXT,
	font: token('font.heading.large'),
	textAlign: 'center',
	flex: 1,
	marginTop: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SummarySubTitle = styled.p<{ isSkeleton: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: ({ isSkeleton }) => (isSkeleton ? 'transparent' : Tokens.COLOR_TEXT),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: ({ isSkeleton }) => (isSkeleton ? Tokens.COLOR_SKELETON : 'transparent'),
	fontWeight: 400,
	margin: `${token('space.150', '12px')} auto 0`,
	maxWidth: '640px',
	/* Override line-height to make it easier to read multiple lines */
	lineHeight: token('space.300', '24px'),
	borderRadius: '4px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SummaryTitleWrapper = styled.div({
	display: 'flex',
	width: '100%',
	justifyContent: 'space-around',
	alignItems: 'center',
	minHeight: '32px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SummaryTitleSideWrapper = styled.div({
	minWidth: '130px',
	display: 'flex',
	justifyContent: 'end',
	// Workaround for the #dst-accessibility testing inline modal rendering
	textAlign: 'left',
});
