import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import messages from './messages';

type TimeBasedGreeting = {
	emoji: string;
	message: MessageDescriptor;
};

export const getTimeBasedGreeting = (): TimeBasedGreeting => {
	const dateNow = new Date();
	const hourNow = dateNow.getHours();
	const minutesNow = dateNow.getMinutes();

	// Morning between 5:00am and 11:59am
	if (hourNow >= 5 && hourNow < 12) {
		return { emoji: '☕️', message: messages.morningGreeting };
	}

	// Afternoon between 12:00pm and 7:29pm
	if ((hourNow >= 12 && hourNow < 19) || (hourNow === 19 && minutesNow < 30)) {
		return { emoji: '☀️', message: messages.afternoonGreeting };
	}

	// Evening between 7:30pm and 4:59am
	return { emoji: '🌔', message: messages.eveningGreeting };
};
