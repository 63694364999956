import { useEffect, useMemo } from 'react';
import { usePagination } from '@atlassian/jira-business-pagination';
import type { IssuesByType } from '@atlassian/jira-business-summary-services/src/services/types.tsx';
import useFetchIssuesByType from '../../services/issues-by-type';
import { useIssuesByType } from '../summary-context';
import type { IssuesByTypeType } from './types';

const usePagedIssuesByType = (): IssuesByTypeType => {
	const { data: initialData, loading: initialLoading, error: initialError } = useIssuesByType();

	const fetchIssuesByType = useFetchIssuesByType();

	const {
		data: issueTypes,
		error: nextPageError,
		hasNextPage,
		loadingNext,
		fetchNext,
		reset,
	} = usePagination<IssuesByType, Error>(fetchIssuesByType);

	useEffect(() => {
		if (initialData != null) {
			reset({ data: initialData.data, pageInfo: initialData.pageInfo });
		}
	}, [initialData, reset]);

	const data = useMemo(
		() =>
			initialData && issueTypes
				? { issueTypes, totalUnresolvedIssues: initialData.totalUnresolvedIssues }
				: null,
		[issueTypes, initialData],
	);

	return {
		data,
		loading: initialLoading,
		loadingNext,
		error: initialError || nextPageError,
		hasNextPage,
		fetchNext,
	};
};

export default usePagedIssuesByType;
