import React, {
	memo,
	useMemo,
	type ReactElement,
	type ReactNode,
	type SyntheticEvent,
} from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { Link } from '@atlassian/react-resource-router';
import PercentageBar from './percentage-bar';
import { calcPercentage } from './utils';

type Props = {
	iconBefore: ReactNode;
	itemCount: number;
	totalCount: number;
	barColor?: string;
	name: string;
	linkUrl: string;
	linkTarget?: '_self' | '_blank';
	onRowLinkClick?: (event: SyntheticEvent) => void;
	actions?: ReactElement | null;
};

const PercentageBarRow = ({
	iconBefore,
	itemCount,
	totalCount,
	barColor,
	name,
	linkUrl,
	onRowLinkClick,
	linkTarget,
	actions,
}: Props) => {
	const percentage = useMemo(() => calcPercentage(totalCount, itemCount), [totalCount, itemCount]);

	const content = (
		<>
			<IdentifierCell>
				{iconBefore}
				<IdentifierName>{name}</IdentifierName>
				{actions != null && <ActionsWrapper>{actions}</ActionsWrapper>}
			</IdentifierCell>
			<td>
				<PercentageBar percentage={percentage} color={barColor} />
			</td>
			<CountCell>
				<Link href={linkUrl} target={linkTarget} onClick={onRowLinkClick}>
					{itemCount}
				</Link>
			</CountCell>
		</>
	);

	return expVal(
		'jwm_summary_page_inline_issue_types_change',
		'isInlineIssueTypesEnabled',
		false,
	) ? (
		<Row>{content}</Row>
	) : (
		<tr>{content}</tr>
	);
};
export const IDENTIFIER_COLUMN_WIDTH = 160;
export const COUNT_COLUMN_WIDTH = 60;

export default memo<Props>(PercentageBarRow);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const Row = styled.tr({
	position: 'relative',

	'&:hover,&:focus-within': {
		backgroundColor: token('elevation.surface.hovered'),
		'--actions-opacity': '1',
		'--actions-width': 'auto',
	},

	'&:hover::before,&:focus-within::before': {
		content: '""',
		height: '100%',
		paddingLeft: token('space.100'),
		borderTopLeftRadius: token('border.radius'),
		borderBottomLeftRadius: token('border.radius'),
		position: 'absolute',
		top: token('space.0'),
		left: token('space.negative.100'),
		backgroundColor: token('elevation.surface.hovered'),
	},

	'&:hover::after,&:focus-within::after': {
		content: '""',
		height: '100%',
		paddingRight: token('space.100'),
		borderTopRightRadius: token('border.radius'),
		borderBottomRightRadius: token('border.radius'),
		position: 'absolute',
		top: token('space.0'),
		right: token('space.negative.100'),
		backgroundColor: token('elevation.surface.hovered'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const ActionsWrapper = styled.div({
	display: 'flex',
	flex: 0,
	opacity: 'var(--actions-opacity, 0)',
	width: 'var(--actions-width, 0)',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IdentifierCell = styled.td({
	display: 'flex',
	gap: token('space.100'),
	alignItems: 'center',
	width: `${IDENTIFIER_COLUMN_WIDTH}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minHeight: `${4.5 * gridSize}px`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IdentifierName = styled.div({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CountCell = styled.td({
	width: `${COUNT_COLUMN_WIDTH}px`,
	textAlign: 'right',
	fontWeight: 500,
});
