import React, { memo, type SyntheticEvent } from 'react';
import AddPeople from '@atlassian/jira-business-invite-people/src/ui/add-people-with-avatars/add-people/index.tsx';
import InviteTeammateButton from './invite-teammate-button';

type Props = {
	onClick: (arg1: SyntheticEvent) => void;
};

const InviteTeammate = ({ onClick }: Props) => (
	<AddPeople>
		<InviteTeammateButton onClick={onClick} />
	</AddPeople>
);

export default memo<Props>(InviteTeammate);
