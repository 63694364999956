import type { CollaborationGraphResponse, RelatedProjectsDataType } from './types';

export const transformData = (
	graphResponse: CollaborationGraphResponse,
	currentProjectId: string,
): RelatedProjectsDataType =>
	graphResponse.recommendedEntities
		.map(({ expand: { id, key, name, avatarUrl, url, projectTypeKey } }) => ({
			id,
			key,
			name,
			avatarUrl,
			url,
			projectTypeKey,
		}))
		.filter(({ id }) => id !== currentProjectId);
